#product-list {
	.filter {
		margin-top: 30px;
	}
	.products {
		margin-top: 30px;
	}

}

.product-detail-page {
	padding-bottom: 60px;
	.building-info {
		box-shadow: 0 0 15px #c5c5c5;
		.title {
			background-color: #FF6600;
			color: #FFF;
			padding: 15px 30px;
			font-weight: bold;
			font-size: 18px;
			.address {
				margin-top: 5px;
				font-weight: normal;
				font-size: 14px;
			}
		}
		table {
			margin-top:10px;
			tr {
				margin-bottom: 5px;
				td {
					padding: 8px 0px 8px 30px;
					.label, .value {
						font-weight: normal;
						color: #562200;
						font-size: 15px;
					}
				}
			}
		}
	}

	.staff {
		margin-top: 30px;
		border-top: 10px solid #FF6600;
		box-shadow: 0 0 15px #c5c5c5;
		.profile {
			padding: 15px 30px;
			text-align: center;
			img {
				border-radius: 50%;
				width: 100px;
			}
		}
		.info {
			.name {
				font-weight: bold;
				margin: 30px 0px 0px 0px;
			}
			.contact {
				margin: 10px 0px;
				color: #FF6600;
				font-weight: bold;
				img {
					width: 18px;
					top: 3px;
				}
			}
		}
	}

	.enquiry-form {
		margin-top: 30px;
		border-top: 10px solid #FF6600;
		box-shadow: 0 0 15px #c5c5c5;
		padding: 30px;
		.title {
			font-size: 18px;
			font-weight: bold;
			margin-bottom: 30px;
		}
		label {
			font-weight: normal;

		}
	}
}

section {
	&#product-list {
		.search-container {
			display: block;
			margin-bottom: 30px;
			width: 100%;

			input[type=text] {
			    width: 420px;
			    font-size: 17px;
			    border: 1px solid #ddd;
				display: inline;
				&:focus {
					outline: none;
				}
			}
			button {
				padding: 11px 17px;
			    background: #ddd;
			    font-size: 17px;
			    height: 42px;
			    border: none;
			    cursor: pointer;
			    top: 1px;
			    left: -5px;

				&:hover {
  					background: #ccc;
				}
			}
		}
	}
}
