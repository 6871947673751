.cart-grid {
	border: 1px solid #C1B192;
	border-top: 0px;
}

.cart-table-footer {
	.coupon {
		padding: 25px 15px;
		input {
			display: inline-block;
		}
	}
	.cart-totals {
		margin-top: 30px;
		padding: 0 15px;
		.form-title {
			margin-bottom: 15px;
			border-bottom: 1px solid #afafaf;
		}
		table {
			tr {
				border-bottom: 0px;
			}
			td {
				padding: 12px 0;
			}
		}
	}
	.cart-control {
		padding: 25px 15px;
		// padding-right: 15px;
	}
}