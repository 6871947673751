#checkout-page {
	.billing-fields {
		padding: 30px;
		@media (max-width: 767px) {
			margin-bottom: 30px;
		}
		.form-title {
			margin-bottom: 15px;
		}
		#checkout_info_label {}
	}
	.checkout-review-order {
		padding: 30px;
		.form-title {
			margin-bottom: 15px;
		}
	}
	.payment_methods {
		[value^='paypal'] {
			top: 10px;
		}
		.font-bold {
			font-size: 16px;
			margin-bottom: 5px;
		}
		p {
			font-size: 13px;
			color: #555;
			line-height: 18px;
		}
		.paypal {
			> div {
				display: inline-block;
				vertical-align: top;
			}
			.text {
				line-height: 43px;
			}
		}
	}
}