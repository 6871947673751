body {
	background-image: url('/images/bg.png');
	background-repeat: repeat-y;
	background-size: cover;
	font-family: 'Arial', 'Microsoft JhengHei', sans-serif;
}

strong {
	font-weight: bold;
}

p {
	font-size: 15px;
	margin-bottom: 25px;
	line-height: 26px;
}

.header-bar,
.header-icon {
	display: inline-block;
}

a {
	color: #39393B;
	text-decoration: none!important;
}
.btn {
	font-weight: bold;
	img.icon {
		top: 1px;
	}
}
.container {
	width: 100%;
}
.searchBox {
	text-align: center;
	padding: 10px 2px 8px 20px;
	border:1px solid #ccc;
	border-radius: 0px;
	width: 100%;
	margin: 0 auto;
	margin: 0px auto;
	color: #3C3B3B;

	input[type='text'] {
		width: 100%;
		border:0 !important;
		height: 16px !important;
		outline-width: 0;
		&::placeholder {
			color: #ccc;
		}
	}

	.fa-search {
		position: absolute;
		left: 10px;
		top: 9px;
		color: #ccc;
	}
}

.filterBox {
	background-color: #C5EBFF;
	border-radius: 20px;
	padding: 30px 0px 30px 220px;
	color: #0845A2;
	font-weight: bold;
	max-width: 1200px;
    margin: 0 auto;
	&::before {
		content: " ";
		position: absolute;
		left: 20px;
		top: -69px;
		width: 162px;
		height: 197px;
		background: url('/images/home_search_img.png');
	}
	select {
		// -moz-appearance:none; /* Firefox */
		// -webkit-appearance:none; /* Safari and Chrome */
		// appearance:none;
	}
	a {
		background-color: #FF6100;
		color:#fff;
		margin: 5px 20px;
		display: inline-block;
	}
}
.products {
	.item {
		margin-bottom: 50px;
		.info {
			margin-top: 10px;
			>div {
				margin-bottom: 5px;
			}
			.building {
				color: #562200;
				font-weight: bold;
			}
			.location {
				color: #562200;
				font-weight: normal;
			}
			.detail {
				color: #39393B;
				font-weight: normal;
			}
			.price {
				margin-top: 10px;
				color: #FF6600;
				font-weight: bold;
			}
			.updateDt {
				color: #a0a0a0;
				font-weight: normal;
				font-size: 14px;
			}
		}
	}
}
.bg-yellow {
	background-color: #fcde62;
}

.bg-grey {
	background-color: #fbfbfb;
}

.bg-white {
	background-color: #fff;
}

.no-padding {
	padding: 0px;
}

.section-padding {
	// padding-top: 53px;
	padding-bottom: 130px;
}

.mobile-cell {
	@media (max-width: 640px) {
		width: 100%!important;
	}
}

.flex-container {
	display: flex;
	flex-flow: row wrap;
	>* {
		flex: 1;
	}
}

.section-padding-70 {
	padding: 70px 0px;
	+.section-padding-70 {
		padding-top: 0px;
	}
}

.static-banner {
	>div {
		min-height: 245px;
		background-size: cover;
		background-position: center center;
	}
}

.breadcrumb-section {
	.breadcrumb {
		padding-top: 30px;
		padding-bottom: 15px;
		background-color: transparent;
		li {
			color: #000!important;
			font-size: 17px;
			a {
				color: #000!important;
				font-size: 17px;
			}
			+li {
				&::before {
					content: '>';
					color: #000!important;
				}
			}
		}
	}
}

.page-heading-wapper {
	background-color: #fafafa;
	.page-heading {
		padding: 20px 0;
		margin: 0;
		font-size: 24px;
		color: #3f3f3f;
		text-transform: uppercase;
	}
}

.page-nav-menu {
	margin-bottom: 30px;
	>li {
		color: #FFFFFF;
		font-size: 16px;
		padding: 20px 20px 20px 20px;
		font-weight: normal;
		&.active {
			background-color: #D6CCB740;
		}
		+li {
			border-top: 1px solid #fff;
		}
		>a {
			display: block;
			padding: 6px 15px;
			cursor: pointer;
			color: #534741;
			font-weight: bold;
		}
		>ul>li {
			&.active {
				background-color: #D6CCB740;
			}
			a {
				color: #b9a989;
				line-height: 1.2;
				display: block;
				padding: 4px 0px;
				padding-left: 30px;
				&:hover {
					background-color: #D6CCB740;
				}
			}
		}
	}
}

.standard-content {
	padding: 25px 30px;
	margin-bottom: 50px;
	h2 {
		color: #000;
		margin-top: 10px;
		margin-bottom: 10px;
		font-weight: bold;
		&.head-title {
			display: block;
			background-color: #fec400;
			padding: 12px;
			color: #7a0026;
			font-weight: bold;
			margin-bottom: 15px;
		}
	}
	img {
		margin-bottom: 10px;
	}
	h1 {
		color: #000000;
		margin-top: 10px;
		margin-bottom: 10px;
		font-weight: bold;
	}
	ul {
		list-style: decimal;
		padding-left: 15px;
		>li {
			font-size: 17px;
			color: #000000;
			line-height: 26px;
		}
	}
}

.accordion-menu-theme {
	margin-bottom: 30px;
	ul {
		>li {
			font-size: 14px;
			&.has-children {
				>a {
					&:after {
						content: '\e876';
						font-family: 'Linearicons-Free';
						font-size: 25px;
						position: absolute;
						right: 15px;
					}
				}
				&.active {
					>a {
						&:after {
							content: '\e874';
						}
					}
				}
			}
			&.active {
				>a {
					font-weight: bold;
				}
			}
			a {
				color: #000;
				// font-weight: bold;
				line-height: 25px;
			}
			+li {
				border-top: 1px solid #fff;
			}
			>a {

				display: block;
				padding: 6px 15px;
			}
			>ul {
				display: none;
				background: #fefdec;
				&.expaned {
					display: block;
				}
				>li {
					&.active {
						>a {
							background-color: #fefdec;
							color: #7a0026;
						}
					}
					>a {
						font-size: 17px;
						color: #000;
						padding: 10px 20px;
						font-weight: normal;
					}
				}
			}
		}
	}
}

#page-404 {
	padding-top: 50px;
	padding-bottom: 130px;
	.page-404 {
		padding: 100px 0 110px 0;
		.heading {
			font-size: 168px;
			line-height: 202px;
			margin-bottom: 15px;
			font-weight: bold;
		}
		.title {
			font-size: 30px;
			line-height: 36px;
			margin-bottom: 15px;
			color: $color-orange4;
		}
		p {
			font-size: 15px;
			margin-bottom: 16px;
			line-height: 25px;
		}
	}
}

#thankyou-page {
	.info {
		border: 2px solid #efefef;
		margin-bottom: 20px;
		padding: 20px 35px;
		text-align: center;
		font-size: 18px;
		line-height: 30px;
		color: #343434;
		font-family: 'Lato', sans-serif;
	}
}

.multi-level-menu {
	.sub-menu {
		// padding-left: 30px;
	}
}

.mini-cart {
	&:hover {
		.mini-cart-content {
			opacity: 1;
			visibility: visible;
			@include transform_translateY (0);
		}
	}
	.mini-cart-content {
		position: absolute;
		top: 100%;
		right: 0;
		background-color: #fff;
		min-width: 320px;
		border: 1px solid #e0e0e0;
		padding: 30px;
		z-index: 100;
		opacity: 0;
		visibility: hidden;
		@include transition_ease (0.5s);
		@include transform_translateY (21px);
	}
	.minicart-list {
		display: block;
		max-height: 320px;
		overflow-y: auto;
		-webkit-overflow-scrolling: touch;
		.item {
			display: block;
			padding: 20px 0;
			border-bottom: 1px solid #e0e0e0;
			overflow: hidden;
			.thumb {
				float: left;
				width: 50px;
				a {
					display: inline;
				}
			}
			.info {
				overflow: hidden;
				padding-left: 15px;
				.product-name {
					font-size: 16px;
					margin-bottom: 15px;
					a {
						color: #333;
					}
				}
				.price {
					color: #b9b9b9;
					font-size: 16px;
				}
			}
		}
	}
	.subtotal {
		padding: 15px 0px;
		.amount {
			color: #333;
			float: right;
			font-weight: 700;
			font-size: 24px;
		}
	}
}