.owl-theme {
	overflow: hidden;
	&:hover {
		.owl-nav {
			[class*=owl-] {
				visibility: visible;
				opacity: 1;
				padding: 0;
				margin: 0;
			}
			.owl-prev {
				left: 0;
			}
			.owl-next {
				right: 0;
			}
		}
	}
}

.owl-nav {
	position: absolute;
	width: 100%;
	top: 50%;
	@include transform_translateY(-50%);
	margin-top: 0px!important;
	span {
		display: inline-block;
		img {
			width: 40px;
		}
	}
	@media (max-width: 767px) {
		[class*=owl-] {
			visibility: visible!important;
			opacity: 1!important;
		}
		.owl-prev {
			left: 0px!important;
		}
		.owl-next {
			right: 0px!important;
		}
	}
	[class*=owl-] {
		position: absolute;
		top: 50%;
		padding: 0!important;
		margin: 0!important;
		@include transform_translateY(-50%);
		background-color: transparent!important;
		visibility: hidden;
		opacity: 0;
		@include transition_ease (0.3s);
	}
	.owl-prev {
		left: -73px;
	}
	.owl-next {
		right: -73px;
	}
}