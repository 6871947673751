.products {
    .item {
        a {
            background-position: center;
            background-size: cover;
            height: 250px;
            display: block;
            background-color: #f2f2f2;
        }
    }
}