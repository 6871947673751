#home-page {
	color: #39393B;
	#home-banner-grid{
		background-image: url('/images/home_banner_bg.png');
		background-repeat: no-repeat;
		background-size: cover;
		padding: 50px 0px;

		.left-box {
			border-top: 10px solid #FF6600;
			padding: 0 40px;
			box-shadow: 0 0 10px #f2f2f2;
			background-color: #fff;
			padding-bottom: 20px;

			a {
				color: #562200;
				font-weight: bold;
				&.selected {
					border-bottom: 2px solid #ff6600;
				}
				&+a {
					margin-left: 20px;
				}

			}
			button {
				color: #FFF;
			}
			.row {
				&+.row {
					margin-top: 15px;
				}
			}
		}
	}

	#main-content {
		margin: 60px 0px;
		font-size: 15px;
		line-height: 18px;

		section+section {
			margin-top: 60px;
		}

		.consultant {
			margin-top: 60px;
			border-top: 10px solid #ff6600;
			background-color: #efefef;
			overflow: hidden;
			.border+.border {
				border-left: 1px solid #d1d1d1;
				margin-bottom: 20px;
			}
			.item {
				padding: 20px;
				a {
					display: block;
					text-align: center;
					img {
						border-radius: 50%;
						width: 50%;
					}
				}

				.info {
					margin-top: 20px;
					.name {
						font-weight: bold;
					}
					.contact {
						margin: 5px 0px;
						font-weight: bold;
						img {
							width: 18px;
							top: 3px;
						}
						a {
							text-align: left;
							color: #FF6600;
						}
					}
				}
			}
		}
	}
	.adv {
		padding-top: 70px;
		a {
			display: block;
			&+a {
				margin-top: 10px;
			}
		}
	}
	.section-margin {
		margin-top: 50px;
		margin-bottom: 50px;
	}
}