.btn-cell {
	.btn + .btn {
		margin-right: 15px;
	}
}
a,
button {
	&.btn-md {

	}
	&.btn-lg {
		width: 100%;
		max-width: 165px;
	}
	&.btn-reset {
		background-color: #C1B192;
		color: #fff;
		font-size: 17px;
		padding: 9px 40px;
		border: none;
		border-radius: 0px;
		&:hover {
			&:active {
				background-color: #534741;
				color: #fff;
			}
		}
		&:hover,
		&:focus,
		&:active {
			background-color: #534741;
			color: #fff;
		}
	}
	&.btn-default {
		display: inline-block;
		background-color: #FF6600;
		color: #fff;
		font-size: 16px;
		padding: 10px 50px;
		border: none;
		border-radius: 0px;
		border: none;
		@include transition_ease (0.3s);
		.fas {
			margin-left: 20px;
		}
		&:hover {
			&:active {
				background-color: #A20055;
				color: #fff;
			}
		}
		&:hover,
		&:focus,
		&:active {
			background-color: #A20055;
			color: #fff;
		}
	}
	&.btn-success {
		color: #fff;
    	background-color: #5cb85c;
    	border-color: #4cae4c;
	}
	&.btn-gray {
		background-color: #959595;
		color: #fff;
		font-size: 17px;
		padding: 9px 40px;
		border: none;
		border-radius: 0px;
	}
}

.quantity {
	border: 2px solid #efefef;
	display: table;
	margin: 0 auto;
	background: #fff;
	.quantity-minus, .quantity-plus, .qty {
		display: table-cell;
		vertical-align: middle;
		text-align: center;
	}
	.quantity-minus, .quantity-plus {
		width: 42px;
		height: 42px;
		line-height: 42px;
		font-size: 16px;
		font-family: 'Lato', sans-serif;
		color: #1d1d1d;
	}
	.quantity-minus {

	}
	.quantity-plus {

	}
	.qty {
		border: 2px solid #efefef!important;
		border-top: 0px!important;
		border-bottom: 0px!important;
		padding: 0;
		height: 42px;
		width: 50px;
		font-size: 18px;
		color: #1d1d1d;
		background-color: transparent;
	}
}