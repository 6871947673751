input[type='text'],
input[type='number'],
input[type='email'],
input[type='password'] {
	height: 42px!important;
	border-radius: 0px!important;
	// border: 0px!important;
	padding: 6px 12px;
	display: block;
	box-shadow: none;
	border: 1px solid #ded0b5;
	&.input-text {
		background-color: #f4f4f4;
	}
}

select {
	// height: 42px!important;
	border-radius: 0px!important;
	border: 1px solid #D6CCB7;
	padding: 6px 12px;
	display: inline-block;
	box-shadow: none;
}

textarea {
	border-radius: 0px!important;
	// border: 0px!important;
	border: 1px solid #e0e0e0;
	resize: none;
}

small {
	display: block;
	margin-top: 10px;
	font-size: 13px;
	color: #959595;
	a {
		color: #959595;
	}
}