.font-bold {
	font-weight: bold;
}

.font-13 {
	font-size: 13px;
}

.font-15 {
	font-size: 15px;
}

.font-17 {
	font-size: 17px;
}

.font-20 {
	font-size: 20px;
}

.font-25 {
	font-size: 25px;
}