h1 {
	color: #FF6600;
	font-size: 25px;
	font-weight: bold;
	line-height: 30px;
	margin: 20px 0px;
	&.section-title {
		text-align: center;
		margin-bottom: 15px;
		> div {
			padding: 0 25px;
			display: inline-block;
			&:before {
				content: '';
				background-image: url('/images/icon-1.png');
				width: 18px;
				height: 20px;
				position: absolute;
				left: 0px;
				top: 50%;
				@include transform_translateY (-50%);
			}
			&:after {
				content: '';
				background-image: url('/images/icon-1.png');
				width: 18px;
				height: 20px;
				position: absolute;
				right: 0px;
				top: 50%;
				@include transform_translateY (-50%);
			}

		}
	}
}

h2 {
	color: #444;
	font-size: 20px;
	line-height: 25px;
}

h3 {
	color: #444;
	font-size: 17px;
	line-height: 23px
}

h4 {
	color: #562200;
	font-weight: bold;
	font-size: 15px;
	margin-bottom: 20px;
}

h5 {
	color: $color-white;
	font-size: 13px;
	line-height: 18px;
}

h6 {
	color: #444;
	font-size: 11px;
	line-height: 16px;
}