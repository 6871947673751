html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video
{
	margin: 0;
	padding: 0;
	border: 0;
	font-size: 100%;
	font: inherit;
	vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section
{
	display: block;
}

body
{
	line-height: 1;
}
ol, ul
{
	list-style: none;
}
blockquote, q
{
	quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after
{
	content: '';
	content: none;
}
table
{
	border-collapse: collapse;
	border-spacing: 0;
}
*
{
	position: relative;
	box-sizing: border-box;
	text-decoration: none;
}
@media (min-width: 1200px) {
    .container{
        max-width: 1440px;
    }
}
body {
	&.rm-blocking {
		overflow: hidden;
	}
}

header {
	&#resp-header {
		display: none;
		// position: absolute;
		width: 100%;
		// top: 0;
		// left: 0;
		.resp-header {
			z-index: 999;
			border-bottom: 2px solid #D3B571;
			background-color: #fff;
			padding-top: 10px;
			@include transition_ease(0.3s);
			// height: 60px;
			a {
				&.logo {
					padding: 10px 80px;
					// height: 80px;
					text-align: center;
					display: block;
					// padding-right: 25px;
					.resp-header-white {
						display: block;
						margin: auto;
					}
					.resp-header-black {
						display: none;
						margin: auto;
					}
					img {
						max-width: 100%;
						vertical-align: middle;
						max-width: 125px;
					}
				}
				&#menu-button {
					position: absolute;
					top: 8px;
					display: inline-block;
					width: 50px;
					height: 100%;
					padding: 5px 10px;
					left: 5px;
					// position: absolute;
					cursor: pointer;
					.line-box {
						width: 100%;
						height: 22px;
						display: inline-block;
						position: relative;
						.inner {
							top: auto;
							bottom: 0;
							width: 100%;
							height: 2px;
							background-color: #d3b570;
							border-radius: 2px;
							position: absolute;
							display: block;
							margin-top: -2px;
							@include transition_ease(0.3s);
							&:before,&:after {
								@include transition_ease(0.3s);
								content: '';
								display: block;
								width: 100%;
								height: 2px;
								background-color: #d3b570;
								border-radius: 2px;
								position: absolute;
							}
							&:before {
								top: -10px;
							}
							&:after {
								top: -20px;
							}
						}
					}
					&.is-active {
						.inner {
							@include transform_3d_rotate(0, -10px, 0, -45deg);
							&:before {
								top: 0px;
								@include transform_rotate (-90deg);
							}
							&:after {
								top: 0;
								opacity: 0;
							}
						}
					}
					// span {
					// 	background: #e7814d;
					// 	display: block;
					// 	height: 3px;
					// 	margin: 9px 0;
					// }
					// &:after,
					// &:before {
					// 	content: '';
					// 	display: block;
					// 	background: #e7814d;
					// 	height: 3px;
					// }
				}
			}
			&.is_sticky {
				background-color: #fff;
				.logo {
					.resp-header-white {
						display:none;
						margin: auto;
					}
					.resp-header-black {
						display:block;
						margin: auto;
					}
				}

				#menu-button {
					.line-box {
						.inner {
							background-color: #000;
							&:after {
								background-color: #000!important;
							}
							&:before {
								background-color: #000!important;
							}
						}
					}
				}
			}
		}
		#resp-menu {
			display: none;
			background-color: #fff;
			position: absolute;
			z-index: 99;
			width: 100%;
			box-shadow: 0px 0px 10px #888888;
			overflow-scrolling: touch;
			-webkit-overflow-scrolling: touch;
			ul {
				&.sub-menu {
					border-bottom: 1px solid #bbbbbb;
					display: none;
					&.active {
						display: block!important;
					}
					> li {
						&.hasChild {
							.sub-menu {
								border-bottom: 0px;
								padding-left: 20px;
								> li {
									> a {
										border-bottom: 0px;
									}
								}
							}
						}
						> a {
							padding-left: 40px;
							// margin-left: 40px;
							border-bottom: 0px;
							border-top: 0;
						}
						&:last-child {
							> a {
								border-bottom: 1px solid #bbbbbb;
							}
							&:not(.has-children) {
								> a {
									border-bottom: 0;
								}
							}
						}
					}
				}
				li {
					a {
						color: #464646;
						font-size: 15px;
						text-decoration: none;
						margin: 0;
						padding: 10px 30px;
						padding-bottom: 5px;
						border-bottom: 1px solid #bbbbbb;
						display: block;
						line-height: 30px;
						text-align: left;
						&.rm-open {
							&:after {
								content: '\e874';
								font-family: 'Linearicons-Free';
								position: absolute;
								right: 35px;
								top: 50%;
								@include transform_translateY(-50%);
								font-size: 12px;
							}
						}
						&.rm-close {
							&:after {
								content: '\e876';
								font-family: 'Linearicons-Free';
								position: absolute;
								right: 35px;
								top: 50%;
								@include transform_translateY(-50%);
								font-size: 12px;
							}
						}
					}
				}
			}
		}
		.menuWrapper {
			display: none;
			background: rgba(0, 0, 0, 0.5);
			position: fixed;
			width: 100%;
			height: 9999px;
			top: 0;
			left: 0;
			z-index: 2;
		}
	}
}
img {
	max-width: 100%;
}

.text-bold {
	font-weight: bold;
}

.vertical-center {
	position: absolute;
	top: 50%;
	left: 0;
	-webkit-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
	width: 100%;
}

#scrollUp {
	background-image: url("/images/top.png");
	bottom: 20px;
	right: 20px;
	width: 38px;
    /* Width of image */
    height: 38px;
    /* Height of image */
}
