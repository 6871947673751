.article-menu-list {
	background-color: #faea9d;
	> li {
		+ li {
			border-top: 1px solid #fff;
		}
		a {
			font-size: 20px;
			display: block;
			color: #000;
			padding: 16px 18px;
		}
	}
}
.static-content-grid {
	padding: 25px 30px;
	background-color: #fff;
	margin-bottom: 70px;
	h1 {
		color: #7a0026;
		margin-bottom: 10px;
	}
	img {
		margin-bottom: 15px;
	}
	p {
		font-size: 17px;
		color: #000000;
		margin-bottom: 25px;
		line-height: 26px;
	}
}