#histroy-list-page {
	.wrapper {
		padding: 20px 30px;
		border: 2px solid #ccc;
		.form-title {
			margin-bottom: 25px;
		}
	}
}

#history-detail-page {
	.wrapper {
		padding: 20px 30px;
		border: 2px solid #ccc;
		.form-title {
			margin-bottom: 25px;
		}
	}
}