.section-title-wrapper {
	text-align: center;
	.section-title {
		&:before {
			content: url(/images/header1_left.png);
			position: absolute;
			left: -14px;
		}
		&:after {
			content: url(/images/header1_right.png);
			position: absolute;
			right: -14px;
		}
		color: #3C3B3B;
		font-weight: bold;
		font-size: 28px;
		text-transform: uppercase;
		display: inline-block;
		padding: 0px 40px;
		z-index: 1;
		line-height: 40px;
		margin: 30px 0px;
	}
	.line {
		border-top:1px solid #D6CCB7; width: 100%; display: block; top: -20px;
	}
}
.static-page-content {
	padding-top: 40px;
}

.section-top-95 {
	padding-top: 95px;
}

.input-group .form-control,
.input-group-btn:last-child>.btn, .input-group-btn:last-child>.btn-group {
	z-index: 1;
}

.product-detail-page {
	padding-top: 70px;
}

.product-menu-heading {
	color: #000;
	margin-bottom: 15px;
	font-size: 16px;
	font-weight: bold;
}

.standard-content {
	padding: 0px;
}

h1 {
	&.product-page-title {
		font-size: 36px;
		font-weight: bold;
		color: #000;
		margin-bottom: 40px;
		text-transform: uppercase;
	}
	&.article-page-title {
		font-size: 36px;
		font-weight: bold;
		color: #000;
		margin-bottom: 80px;
		@media (max-width: 767px) {
			font-size: 30px;
			margin-bottom: 40px;
		}
		@media (max-width: 640px) {
			font-size: 25px;
			margin-bottom: 20px;
		}
	}
}

#checkout-page, #cart-page {
	.checkout-review-order-table, .cart-table {
		tr{
			&.discount {
				.fa-trash {
					color: #bd0000;
					cursor: pointer;
				}
			}
		}
	}
}
.slider {
	width: 100%;
	.no-opacity {
		opacity: 1;
	}
}

.slick-slide {
  margin: 0px 20px;
}

.slick-slide img {
  width: 100%;
}
.slick-prev {
	left: 15px;
	z-index: 1;
}
.slick-next {
	right: 15px;
	z-index: 1;
}
.slick-prev:before,
.slick-next:before {
  color: #969696;
}


.slick-slide {
  transition: all ease-in-out .3s;
  opacity: .2;
}

.slick-active {
  opacity: .5;
}

.slick-current {
  opacity: 1;
}
.pagination>.active>a {
    background-color: #FF6600;
    border-color: #FF6600;
}