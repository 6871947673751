// Product List Css
.products {
	.product-item-grid {
		@media (max-width: 375px) {
			width: 100%;
		}
	}
}

.related-product-list {
	h1 {
		font-weight: bold;
		margin-bottom: 30px;
	}
}

//Product item Css
.product-item {
	// &:before {
	// 	content: '';
	// 	background-image: url('/images/p-bg-top.png');
	// 	position: absolute;
	// 	top: -27px;
    // 	left: 0px;
	// }
	// &:after {
	// 	content: '';
	// 	background-image: url('/images/p-bg-bottom.png');
	// 	position: absolute;
	// 	bottom: -30px;
	// 	left: 0px;
	// }
	background-image: url(/images/p-bg.png);
	background-size: 100% 100%;
    overflow: visible;
	vertical-align: middle;
	padding: 20px 20px;
	// width: 308px;
	.flash {
		position: absolute;
		top: 17px;
		left: 0px;
		z-index: 10;
		width: 100%;
	}
	.product-inner {
		// border: 1px solid #efefef;
		@include transition_ease(0.3s);
		// margin-top: -10px;
		// margin-bottom: -12px;
		z-index: 1;
		&:hover {
			// -webkit-box-shadow: 0px 0px 12px 1px rgba(171,171,171,0.49);
			// -moz-box-shadow: 0px 0px 12px 1px rgba(171,171,171,0.49);
			// box-shadow: 0px 0px 12px 1px rgba(171,171,171,0.49);
		}
		.thumb {
			border-radius: 5px;
			box-shadow: 0px 0px 10px #d6d6d6 !important;
			background: #fff;
			text-align: center;
			// margin-bottom: 12px;
			overflow: hidden;
			padding: 10px 10px 10px 10px;
			margin-bottom: 10px;

			.product-image {
				display: inline;
				line-height: 280px;
				height: 280px;
				img {
					// @include transition_ease(0.3s);
					// max-height: 278px;
					max-height: 280px;
					width: auto;
					margin: auto;
					vertical-align: middle;
					@media (max-width: 1200px) {
						max-height: 144px;
					}
					@media (max-width: 730px) {
						max-height: 130px;
					}
				}
			}
			&:hover {
				// .product-image {
				// 	img {
				// 		@include transform_scale (1.15, 1.15);
				// 	}
				// }
			}
		}
		.info {
			text-align: left;
			h3 {
				height: 30px;
				line-height: 1;
			}
			.product-name {
				display: block;
				color: #818181;
				font-size: 15px;
				font-weight: bold;
				// margin-bottom: 18px;
				// padding-bottom: 10px;
				margin-bottom: 5px;
			}
			.promote-price {
				display: inline-block;
				font-size: 15px;
				color: #A20055;
				padding-bottom: 10px;
				font-weight: normal;
				margin-bottom: 5px;
			}
			.origin-price {
				display: inline-block;
				font-size: 12px;
				padding-bottom: 5px;
				color: #999999;
				text-decoration: line-through;
				font-weight: normal;
			}
		}
	}
	&:hover {
		.group-buttons {
			>a {
				opacity: 1;
				visibility: visible;
				@include transform_scale (1, 1);
			}
		}
	}
	.group-buttons {
		position: absolute;
		left: 0;
		width: 100%;
		bottom: 10px;
		text-align: center;
		z-index: 3;
		>a {
			border: none;
			padding: 0;
			width: 40px;
			height: 40px;
			font-size: 0;
			background-color: #fff;
			display: inline-block;
			line-height: 52px;
			border-radius: 0;
			vertical-align: middle;
			border: 1px solid #D6CCB7;
			@include transition_ease (0.3s);
			@include transform_scale (0, 1);
			opacity: 0;
			visibility: hidden;
			font-size: 0;
			float: initial;
			color: #D6CCB7;
			@media (max-width: 540px) {
				width: 40px;
				height: 40px;
			}
		}
	}
	.add_to_cart:before {
		content: "\e82e";
		font-family: 'Linearicons-Free';
		font-size: 23px;
		position: absolute;
		top: 50%;
		@include transform_translateY(-50%);
		width: 100%;
		left: 0px;
		@media (max-width: 540px) {
			font-size: 19px;
		}
	}
	.added_to_cart:before {
		content: "\f00c";
		font-family: 'FontAwesome';
		font-size: 1.1rem;
		position: absolute;
		top: 50%;
		@include transform_translateY(-50%);
		width: 100%;
		left: 0px;
		@media (max-width: 540px) {
			font-size: 14px;
		}
	}
	.search_button:before {
		content: "\e86f";
		font-family: 'Linearicons-Free';
		font-size: 1rem;
		position: absolute;
		top: 50%;
		@include transform_translateY(-50%);
		width: 100%;
		left: 0px;
		@media (max-width: 540px) {
			font-size: 16px;
		}
	}
	.add_to_wishlist:before {
		content: "\f08a";
		font-family: 'FontAwesome';
		font-size: 1.1rem;
		position: absolute;
		top: 50%;
		@include transform_translateY(-50%);
		width: 100%;
		left: 0px;
		@media (max-width: 540px) {
			font-size: 14px;
		}
	}
	.added_to_wishlist:before {
		content: "\e870";
		font-family: 'Linearicons-Free';
		font-size: 1rem;
		position: absolute;
		top: 50%;
		@include transform_translateY(-50%);
		width: 100%;
		left: 0px;
		@media (max-width: 540px) {
			font-size: 16px;
		}
	}
}


//Product Detail Css
.product-single {
	margin-bottom: 25px;
	.product-single-left {
		@media (max-width: 767px) {
			margin-bottom: 30px;
		}
		.images {
			padding: 30px 15px;
		}
	}
	.product-single-right {
		.summary {
			h1 {
				font-size: 24px;
				color: #000000;
				margin-bottom: 5px;
			}
			.promote-price {
				font-size: 18px;
				color: #534741;
				margin-bottom: 20px;
				font-weight: bold;
			}
			.origin-price {
				font-size: 18px;
				margin-bottom: 10px;
				color: #C1B192;
				text-decoration: line-through;
			}
			p {}
			.quantity {
				border: 2px solid #efefef;
				display: table;
				float: left;
				margin-bottom: 30px;
				margin-right: 35px;
			}
		}
	}
}

.product-detail-page {
	.product-desc {
		margin-bottom: 50px;
		padding: 15px 30px;
		background-color: #fff;
		line-height: 1.2;
		h2 {
			font-weight: bold;
			color: #000;
		}
		p {
			font-size: 17px;
			color: #000;
		}
		li {
			background: url(/images/bullet.png) no-repeat;
			background-position: 5px 3px;
			padding: 2px 0px 2px 25px;
		}
	}
}