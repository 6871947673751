.frm-default-theme {
	label {
		font-size: 15px;
		color: #959595;
		margin-bottom: 10px;
	}
	input {
		background-color: #f4f4f4;
		box-shadow: none;
	}
	select {
		background-color: #f4f4f4;
		box-shadow: none;
		height: 42px;
	}
	.form-title {
		margin-bottom: 30px;
		font-weight: bold;
		margin-bottom: 25px;
		font-size: 25px;
	}
	.form-button-grid {
		margin-top: 30px;
	}
}

form {
	&.login {
		border: 2px solid #ccc;
		margin-bottom: 20px;
		padding: 20px 35px;
		.form-title {
			margin-bottom: 30px;
		}
	}
	&.user {
		border: 0px solid #ccc;
		margin-bottom: 20px;
		padding: 20px 35px;
		.form-title {
			margin-bottom: 30px;
		}
	}
}