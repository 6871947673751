section {
	&#home-blog-grid {
		padding-top: 95px;
		padding-bottom: 40px;
	}
}
.blog-item {
	a {
		display: block;
	}
	figure {
		margin-bottom: 37px;
	}
	.blog-detail {
		.title {
			display: block;
			color: #232222;
			font-size: 14px;
			margin-bottom: 10px;
			font-weight: bold;
		}
		.short-desc {
			color: #777;
			font-size: 16px;
			line-height: 20px;
			margin-bottom: 30px;
		}
		.date {
			color: #232222;
			time {
				color: #bfbfbf;
			}
		}
	}
}

#blog-list-page {
	.blog-item {
		margin-bottom: 30px;
		.title {
			font-size: 16px;
		}
		.blog-detail {
			p {
				color: #777 !important;
				font-size: 16px !important;
				line-height: 20px !important;
				margin-bottom: 15px !important;
			}
		}
	}
}

#blog-detail-page {
	h1 {
		margin-bottom: 15px;
	}
	.date {
		display: block;
		size: 18px;
		margin-bottom: 50px;
	}
	.blog-detail-content {

	}
}