footer {
	border-top: 10px solid #ff6600;
	color: #818181;
	&#desktop-footer {
		// background-color: #D3B571;
		.footer-top {
			line-height: 20px;
			padding: 30px 0;
			background-color: #EEEEEE;

			ul.links {
				li {
					margin: 5px 0px;

					a.link {
						color:#818181;
						padding: 0 20px;
						font-size: 15px;
						&:first-child {
							padding-left: 0px;
						}
						&+a {
							border-left: 1px solid #979797;
						}
					}
				}
			}
			.widget {
				margin-top: 50px;
				address {
					color: #818181;
					font-size: 14px;
					line-height: 20px;
					margin-bottom: 30px;
				}
				p {
					opacity: .8;
					color: #818181;
					line-height: 1.7;
					font-size: 14px;
				}
				.footer-logo {
					display: block;
					margin-bottom: 30px;
				}
				&:first-child {
					margin-top: 0px;
				}
			}
		}
		.footer-middle {
			color: #818181;
			line-height: 20px;
			padding: 10px 0;
			background-color: #EEEEEE;
			text-align: center;
			font-size: 14px;
		}
		.footer-bottom {
			padding: 10px 0;
			color: #fff;
			background-color: #b7b7b7;
			.copyright {
				color: #414141;
				font-size: 14px;
			}
			.copyright-links {
				a {
					display: inline-block;
					margin: 0 3px;
					color: #818181;
					+ a {
						padding-left: 16px;
						&:before {
							content: '/';
							position: absolute;
							top: 0;
							left: 0;
						}
					}
				}
			}
			.other-link {

			}
		}
	}
}