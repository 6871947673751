table {
	width: 100%;
	margin-bottom: 10px;
	border-collapse: collapse;
	margin-bottom: 15px;
	&.shop-table {
		thead {
			th {
				border-top: 1px solid #dfdfdf;
				border-bottom: 2px solid #eee;
				padding: 10px 5px;
				color: #474747;
				font-weight: bold;
				vertical-align: middle;
			}
		}
		tbody {
			th {
				padding: 10px 5px;
				border: none;
				border-bottom: 1px solid #efefef;
				font-weight: bold;
			}
			tr {
				border-bottom: 1px solid #dfdfdf;
			}
			td {
				padding: 11px 15px;
				vertical-align: middle;
				&.product-thumbnail {
					width: 15%;
					.product-thumbnail-wrapper {
						.product-thumbnail-quantity {
							font-size: 15px;
							font-weight: 500;
							white-space: nowrap;
							padding: 2px 10px;
							border-radius: 2em;
							background-color: #c1b192;
							color: #fff;
							position: absolute;
							right: -11px;
							top: -7px;
							z-index: 3;
							line-height: 25px;
						}
					}
				}
				&.product-name {
					width: 50%;
					max-width: 200px;
					font-size: 16px;
					a {
						color: #555;
					}
				}
				&.product-price {
					width: 15%;
				}
			}
		}
		tfoot {
			th {
				font-weight: bold;
			}
			td {
				text-align: right;
			}
		}
	}
	&.cart-table {
		border-collapse: collapse;
		width: 100%;
		thead {
			@media (max-width: 767px) {
				display: none;
			}
			th {
				border-top: 1px solid #C1B192;
				border-bottom: 2px solid #C1B192;
				padding: 15px 22px;
				color: #474747;
				font-weight: bold;
				text-transform: capitalize;
				text-align: center;
			}
		}
		tbody {
			@media (max-width: 767px) {
				display: block;
				padding: 0;
				width: 100%;
				border: none;
			}
			tr {
				@media (max-width: 767px) {
					border-bottom: 1px solid#C1B192;
					overflow: hidden;
					display: block;
					padding: 0;
					width: 100%;
				}
				border-bottom: 1px solid #C1B192;
				&.promotion {
					background-color: #f2f2f2;
					&.title {
						td {
							padding-top: 20px;
							font-size: 18px;
						}
					}
					td {
						padding: 5px 22px;
					}
				}
			}
			td {
				// @media (max-width: 767px) {
				// 	display: block;
				// 	padding: 0;
				// 	width: 100%;
				// 	border: none;
				// }
				border-bottom: 0px;
				border: none;
				padding: 11px 22px;
				font-size: 16px;
				vertical-align: middle;
			}
			.product-price,
			.product-subtotal,
			.product-quantity,
			.product-remove {
				text-align: right;
			}
			.product-thumbnail {
				width: 110px;
			}
			.product-name {
				.name {
					font-size: 16px;
					color: #555;
					display: inline-block;
				}
			}
			.product-quantity {
				@media (max-width: 767px) {
					padding: 0px 22px;
					width: 100%;
					float: left;
					text-align: left;
					.product-title {
						line-height: 29px;
					}
				}
			}
			.product-remove {
				a {
					color: #454545;
				}
				@media (max-width: 767px) {
					position: absolute;
					top: 0;
					right: 0;
				}
			}
			.product-price {
				@media (max-width: 767px) {
					display: block;
					padding-bottom: 0;
					text-align: left;
					> * {
						line-height: 29px;
					}
				}
				.amount {
					font-size: 15px;
					color: #b9b9b9;
					display: inline-block;
				}
			}
			.product-subtotal {
				@media (max-width: 767px) {
					float: left;
					width: 100%;
					padding-top: 0;
					text-align: left;
					> * {
						line-height: 29px;
					}
				}
				.amount {
					font-size: 15px;
					color: #b9b9b9;
					display: inline-block;
				}
			}
			.product-title {
				display: inline-block;
				width: 90px;
				float: left;
			}
			.quantity {
				@media (max-width: 767px) {
					float: left;
					display: block;
					.qty {
						display: table-cell;
						border: 2px solid #efefef;
						border-top: 0;
						border-bottom: 0;
						padding: 0;
						width: 50px;
						font-size: 18px;
						color: #1d1d1d;
						text-align: center;
						float: left;
						max-height: 25px;
					}
					.quantity-minus,.quantity-plus {
						height: 25px;
						line-height: 25px;
						float: left;
					}
				}
			}
		}
	}
	&.checkout-review-order-table {
		th,td {
			padding: 10px 5px;
			border: none;
			border-bottom: 1px solid #efefef;
		}
		thead {
			th {
				border-top: 1px solid #dfdfdf;
				border-bottom: 2px solid #eee;
				padding: 10px 5px;
				color: #474747;
				font-weight: bold;
			}
		}
	}
	&.order-history {
		thead {
			th {
				font-weight: bold;
				font-size: 16px;
				padding: 11px 15px;
				&.cell-address {
					width: 300px;
				}
			}
		}
	}
}