// Color
$color-white: #ffffff;
$color-black: #000000;
$color-red: #9e0b0f;
$color-orange1: #b55028;
$color-orange2: #f26522;
$color-orange3: #b45028;
$color-orange4: #f7941d;

//Font Size
$font-30: 30px;
$font-28: 28px;
$font-24: 24px;
$font-20: 20px;
$font-18: 18px;
$font-15: 15px;
$font-13: 13px;

//Font Family
$font-roboto: 'Roboto', sans-serif;