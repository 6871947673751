.login-grid {
	background-color: #fff;
	padding: 20px 30px;
	border: 1px solid #cccccc;
	@media (max-width: 767px) {
		margin-bottom: 30px;
	}
}

.register-grid {
	background-color: #fff;
	padding: 20px 30px;
	border: 1px solid #cccccc;
}