#contact-page {
	padding-bottom: 60px;
	.contact-banner-grid {
		background: url('/images/sub_banner.png');
		background-repeat: no-repeat;
		background-size: cover;
		height: 300px;
		color: #FFF;
		padding-top: 60px;
		h1 {
			font-size: 24px;
			color: #FFF;
			margin-bottom: 40px
		}
	}
	#main-content {
		top: -70px;
		.contact-info {
			background-color: #fff;
			margin-top: 30px;
			border-top: 10px solid #FF6600;
			box-shadow: 0 0 15px #c5c5c5;
			padding: 40px;
			.title {
				color: #562200;
				font-size: 24px;
				font-weight: bold;
				margin-bottom: 30px;
			}
			p {
				padding-left: 25px;
				position: relative;
				margin-bottom: 0px;
				i {
					position: absolute;
					left: 0px;
					top: 5px;
					color: #562200;
				}
			}
		}
		.enquiry-form {
			background-color: #fff;
			margin-top: 30px;
			border-top: 10px solid #FF6600;
			box-shadow: 0 0 15px #c5c5c5;
			padding: 30px;
			.title {
				font-size: 18px;
				font-weight: bold;
				margin-bottom: 30px;
			}
			label {
				font-weight: normal;

			}
		}
	}
}

#googleMap {
	height: 485px;
	margin-top: 50px;
	width: 100%;
}