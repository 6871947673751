#team-page {
	padding-bottom: 60px;
	.team-banner-grid {
		background: url('/images/sub_banner.png');
		background-repeat: no-repeat;
		background-size: cover;
		height: 300px;
		color: #FFF;
		padding-top: 60px;
		h1 {
			font-size: 24px;
			color: #FFF;
			margin-bottom: 40px
		}
	}
	#main-content {
		top: -70px;
		.staff {
			background-color: #fff;
			margin-top: 30px;
			border-top: 10px solid #FF6600;
			box-shadow: 0 0 15px #c5c5c5;
			.profile {
				padding: 30px 50px;
				img {
					border-radius: 50%;
					width: 120px;
				}
			}
			.info {
				font-size: 15px;
				div+div {
					margin-top: 8px;
				}
				.name {
					font-size: 18px;
					font-weight: bold;
					margin: 30px 0px 15px 0px;
				}
				.contact {
					margin: 5px 0px;
					font-weight: bold;
					font-size: 20px;
					img {
						width: 25px;
						top: 3px;
					}
					a {
						text-align: left;
						color: #FF6600;
					}
				}
			}
		}
	}
}