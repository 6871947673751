header {
	&#header {
		position: relative;
		z-index: 199;
		@media (max-width: 991px) {
			display: none;
		}
		&:not(.home) {
			position: relative;
			left: auto;
		}
		.btnlogin {
			color: #A20055;
		}
		.searchBox {
			width: 20%;
			position: absolute;
			left: 0;
			top: 5px;
		}
		.sticky-bar {
			#logo {
				margin-top: 20px;
				margin-bottom: 20px;
				text-align: center;
				@media (max-width: 1134px) {
					margin-right: 0px;
				}
				@media (max-width: 1086px) {
					padding-right: 0px;
				}
				@media (max-width: 1060px) and (min-width: 992px) {
					max-width: 165px;
				}
				a {
					display: inline-block;
					img {
						height:52px;
					}
				}
				.retina-logo {
					display: none;
				}
				@include transition_ease(0.3s);
			}

			.group-control {
				@include transition_ease(0.3s);
				.text {
					@include transition_ease(0.3s);
					//text-transform: uppercase;
				}
				>ul {
					>li {
						vertical-align: middle;
					}
				}
			}
			&.is_sticky {
				background-color: #fff;
				padding-bottom: 0px;
				box-shadow: 0 0 14px #ececec;
				.group-control {
					.text {
						color: #000;
					}
					.standard-logo {
						display: none!important;
						img {
							max-height: 80px;
						}
					}
					.retina-logo {
						display: block!important;
						img {
							max-height: 80px;
						}
					}
				}
				#logo {
					padding-top: 0px;
					padding-bottom: 0px;
					.standard-logo {
						display: none;
						img {
							max-height: 80px;
						}
					}
					.retina-logo {
						display: block;
						img {
							max-height: 80px;
						}
					}
				}
				#primary-menu {
					>ul {
						>li {
							display: inline-block;
							>a {
								padding: 5px 15px;
								&:hover {
									color: #534741;
								}
							}
						}
					}
				}
			}
		}
		#primary-menu {
			float: left;
			>ul {
				margin: 5px 0px;
				>li {
					display: inline-block;
					>a {
						padding: 2px 15px;
						display: inline-block;
						color: #888888;
						font-weight: normal;
						@include transition_ease(0.3s);

						&:hover {
							color: #534741;
						}
					}
				}
				>li+li {
					border-left: 1px solid #D1D1D1;
				}
				li {
					&.has-children {
						&:hover {
							.sub-menu {
								opacity: 1;
								visibility: visible;
								@include transform_translate(-50%, 0px);
							}
						}
						> a {
							&:after {
								content: "\f107";
								font-family: "Font Awesome 5 Free";
								position: absolute;
								right: 0px;
								top: 50%;
								@include transform_translateY(-50%);
							}
						}
						.sub-menu{
							position: absolute;
							background-color: #f7eedcd1;
							top: 100%;
							left: 50%;
							@include transition_ease (0.3s);
							@include transform_translate(-50%, 20px);
							min-width: 200px;
							padding: 10px 0px;
							opacity: 0;
							visibility: hidden;
							opacity: 0.8 !important;

							> li {
								display: block;
								&:hover {
									background-color: #D6CCB7BE;
								}
								a {
									display: block;
									padding: 1px 15px;
									font-size: 16px;
									font-weight: bold;
									width: 100%;
									color: #534741;
								}
							}
						}
					}
				}
			}
		}
	}

	&#resp-header {
		@media (max-width: 991px) {
			display: block;
			z-index: 2;
		}
		// @media (max-width: 640px) {
		// 	#menu-button {
		// 		position: relative!important;
		// 		padding: 15px 10px !important
		// 	}
		// 	.group-control {
		// 		position: relative!important;
		// 		top: 0px;
		// 		transform: none!important;
		// 		padding-top: 10px;
		// 	}
		// }
		.resp-header {
			&.is_sticky {
				.group-control {
					.standard-logo {
						display: none;
					}
					.retina-logo {
						display: block;
					}
					ul {
						> li {
							.header-icon {
								span.text {
									color: #000
								}
							}
						}
					}
				}
			}
		}
		.group-control {
			// position: absolute;
			right: 15px;
			top: 50%;
			// @include transform_translateY (-50%);
		}
	}

	&.transparent-header {
		background-color: transparent;
	}

	&.full-header {
		.container {
			width: 100%;
			max-width: 1440px;
			padding: 0 30px;
		}
	}

	.header-top {
		background-color: #e9e9e9;
		.msg {
			color: #a1a1a1;
			margin: 0 auto;
			text-align: center;
			padding: 5px;
			font-size: 14px;
		}
	}
	.header-wrap {
		z-index: 199;
		padding: 0;
		&:not(.home) {
			padding: 25px 0px;
			text-align: left;
			.sticky-bar {
				background-color: #fff;
				padding: 0 0 0 0;
				.group-control {
					.text {
						color: #000;
					}
					.standard-logo {
						display: none;
					}
					.retina-logo {
						display: block;
					}
				}
				#logo {
					padding-top: 0px!important;
					.standard-logo {
						display: none!important;
					}
					.retina-logo {
						display: block!important;
					}
				}
			}
		}
	}
	#primary-menu {
		>ul {
			>li {
				display: inline-block;
				>a {
					padding: 0px 15px!important;
					// color: #000!important;
				}
			}
		}
	}
}

.header-control {
	ul {
		display: inline-block;
		>li {
			display: inline-block;
			line-height: 12px;
			vertical-align: top;
			+li {
				margin-left: 5px;
			}
			.header-icon {
				text-decoration: none;
				span {
					&.fa {
						color: #000;
					}
					&.text {
						color: #000;
						font-size: 15px;
					}
				}
			}
		}
	}
}

.currency-dropdown {
	.text {
		border-left: 2px solid;
		border-right: 2px solid;
		padding: 0 5px;
	}
}

.cart-dropdown {
	.cart-icon {
		display: inline-block;
		.standard-logo {
			display: none;
		}
		.retina-logo {
			display: block;
		}
		.count {
			background: #A20055;
			line-height: 1.4;
			padding: 0 5px 1px 4px;
			border-radius: 10px;
			text-align: center;
			font-size: 10px;
			position: absolute;
			top: 0px;
			right: -11px;
			z-index: 2;
			color: #fff;
		}
	}
}

.main-menu {
	font-size: 0px;
	// padding-bottom: 15px;
	>li {
		display: inline-block;
		+li {
			margin-left: 30px;
			@media (max-width: 1300px) {
				margin-left: 15px;
			}
			@media (max-width: 1180px) {
				margin-left: 0px;
			}
		}
		a {
			display: inline-block;
			font-size: 16px;
			color: $color-orange1;
			line-height: 30px;
		}
	}
}

.menu-dropdown {
	position: absolute;
	top: 100%;
	right: 0px;
	min-width: 140px;
	background-color: #fff;
	padding: 0;
	border: 1px solid #e0e0e0;
	z-index: 100;
	opacity: 0;
	visibility: hidden;
	padding: 10px 0;
	@include transform_translateX (21px);
	@include transition_ease (0.5s);
	&.show {
		transform: translateX(0);
		-webkit-transform: translateX(0);
		-ms-transform: translateX(0);
		opacity: 1;
		visibility: inherit;
	}
	li {
		list-style: none;
		position: relative;
		text-align: center;
		display: block!important;
		line-height: normal!important;
		margin: 0px!important;
	}
	a {
		display: block;
		padding: 6px 12px;
		font-size: 15px;
		width: 100%;
		color: #a7a7a7;
		&:hover {
			color: #444;
		}
	}
}